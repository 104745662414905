<template>
  <div class="content md">
    <data-list
      ref="dataList"
      style="height: 100%"
      :url="url"
      query="&expand=holiday"
      entity-name="假期余额"
      :add-btn="null"
      hide-show-detail
      hide-edit
      hide-delete
      :modal-form="{layout: 'horizontal', labelAlign: 'left', labelCol: {span: 4}, wrapperCol: {span: 20}}"
      :modalWidth="600"
      :action-width="200"
      :action="action"
      :columns="columns"
      :default-query="defaultQuery"
      :before-update="beforeUpdate"
      :search-key-type="{user_name: 1}">
      <template v-slot:action="{item, index}">
        <span class="txt-btn grey" style="cursor: default" v-if="item.is_limit == 0">修改余额</span>
        <span class="txt-btn" @click.stop="updateBalance(item, index)" v-else>修改余额</span>
        <span class="txt-btn danger" @click.stop="deleteBalance(item)">删除</span>
        <span class="txt-btn" @click.stop="showChangeList(item)">变动记录</span>
      </template>
      <template v-slot:query="{form}">
        <a-form-model-item label="假期类型">
          <a-select
            style="width: 180px"
            placeholder="选择假期类型筛选"
            :options="holidayList"
            allow-clear
            v-model="form.holiday_id"
            @change="updateList">
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="姓名/法名">
          <a-input v-model="form.user_name" placeholder="输入姓名/法名查询"></a-input>
        </a-form-model-item>
      </template>
      <template v-slot:edit="{form}">
        <a-form-model-item label="姓名/法名">
          <span>{{ form.user_name }}</span>
        </a-form-model-item>
        <a-form-model-item label="身份">
          <span>{{ getStaffTypeName(form.role) }}</span>
        </a-form-model-item>
        <a-form-model-item label="假期类型">
          <span>{{ form.holiday ? form.holiday.name : '--' }}</span>
        </a-form-model-item>
        <a-form-model-item label="假期余额" prop="count">
          <a-input-number v-model="form.count" placeholder="请输入假期余额" style="width: 100%"></a-input-number>
        </a-form-model-item>
      </template>
    </data-list>
    <a-modal :width="640" v-model="visible" :title="modalTitle" :footer="null">
      <holiday-change :holiday-count-id="visible && holidayCount ? holidayCount.id : 0" />
    </a-modal>
  </div>
</template>

<script>
    import { getStaffTypeName } from "../../common/hr/book";
    import { getUserWxId } from "../../common/js/storage";
    import holidayChange from "./holiday-change";

    export default {
        name: "HolidayBalance",
        components: { holidayChange },
        data() {
            return {
                loading: false,
                url: "/admin/holiday-count",
                columns: [
                    {title: '姓名/法名', dataIndex: 'user_name'},
                    {title: '身份', dataIndex: 'role', customRender: getStaffTypeName},
                    {title: '假期类型', dataIndex: 'holiday', customRender: text => text ? text.name : '--'},
                    {title: '假期余额', customRender: text => text.is_limit == 0 ? '不限额' : (text.count < 0 ? <span class="holiday-over">{text.count + '天'}</span> : text.count + '天')},
                  ],
                holidayList: [],
                defaultQuery: {},
                visible: false,
                holidayCount: null,
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
            modalTitle() {
                const holidayCount = this.holidayCount;
                if(holidayCount && holidayCount.holiday) {
                    return `${holidayCount.holiday.name || ""}变动记录`;
                } else {
                    return "变动记录"
                }
            }
        },
        created() {
            // 更新titleBar菜单
            // this.$store.commit("setSubMenus", getTempMenu({is_oa: 1, type: 1}));
            this.getHolidayList();
        },
        methods: {
            getStaffTypeName,
            showChangeList(item) {
                this.holidayCount = item;
                this.visible = true;
            },
            getHolidayList() {
                const url = '/admin/holiday?pageSize=1000&filter[h_type]=1';
                this.$axios(url).then(res => {
                    const list = res.data.map(item => Object.assign({key: item.id, title: `${item.name}-${getStaffTypeName(item.type)}`}));
                    this.holidayList = list;
                });
            },
            deleteBalance(item) {
                this.$confirm({
                    title: '提示',
                    content: "删除后数据不可恢复，确认删除此人的假期余额吗？",
                    onOk: () => {
                        this.$axios({
                            url: `${this.url}/${item.id}`,
                            method: 'DELETE'
                        }).then(() => {
                            this.$message.success('已删除');
                            this.updateList();
                        })
                    }
                });
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            updateBalance(item, index) {
                this.$refs.dataList.editRow(item, index);
            },
            beforeUpdate(form, callback) {
                callback({count: form.count, id: form.id, modify_user_id: getUserWxId()});
            }
        }
    }
</script>

<style lang="less">
.holiday-over {
  color: @error-color;
}
</style>
