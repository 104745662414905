<template>
  <data-list
    ref="dataList"
    class="content lg"
    url="/admin/evaluate-user"
    entity-name="成员"
    hide-show-detail
    hide-edit
    hide-delete
    :add-btn="false"
    :query="`&filter[type]=${type}`"
    :custom-url="customUrl"
    :deal-list="dealList"
    :action="action"
    :columns="columns"
    :rules="rules"
    :before-update="beforeUpdate"
    :action-width="240">
    <template v-slot:prefix v-if="type > 0">
      <a-row :gutter="16" class="book-chart">
        <a-col :span="8">
          <a-card class="book-chart-card" :body-style="{padding: 0}" title="年龄分布" :bordered="false">
            <chart :option="ageChartOptions" class="book-chart-box" />
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card class="book-chart-card" :body-style="{padding: 0}" title="学历分布" :bordered="false">
            <chart :option="educationChartOptions" class="book-chart-box" />
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card class="book-chart-card" :body-style="{padding: 0}" :bordered="false">
            <div class="flex-box justify-between" slot="title">
              <div class="book-chart-title">状态分布</div>
              <div class="book-total">总人数{{ total }}人</div>
            </div>
            <chart :option="statusChartOptions" class="book-chart-box" />
          </a-card>
        </a-col>
      </a-row>
    </template>
    <template v-slot:action="{item}">
      <a class="txt-btn" @click.stop="showDetail(item)">查看</a>
      <a class="txt-btn" @click.stop="editRow(item)">编辑</a>
      <a class="txt-btn" @click.stop="changeStatus(item)">修改状态</a>
      <a class="txt-btn danger" @click.stop="delRow(item)">删除</a>
    </template>
    <template v-slot:query="{form}">
      <a-form-model-item label="部门">
        <select-book type="dept" v-model="form.dept" @change="getList"></select-book>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input-search allow-clear v-model="form.keyword" placeholder="输入姓名/法名搜索" style="width: 180px" @search="getList"/>
      </a-form-model-item>
      <a-form-model-item label="" v-if="type === 2">
        <a-select
            style="width: 180px"
            allowClear
            placeholder="选择性别筛选"
            @change="getList"
            :options="sexOptions"
            v-model="form.sex">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
            style="width: 180px"
            allowClear
            placeholder="选择学历筛选"
            @change="getList"
            :options="educationOptions"
            v-model="form.education">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
            style="width: 180px"
            allowClear
            placeholder="选择年龄段筛选"
            @change="getList"
            :options="ageOptions"
            v-model="form.age">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
            style="width: 180px"
            allowClear
            placeholder="选择状态筛选"
            @change="getList"
            :options="statusOptions"
            v-model="form.status">
        </a-select>
      </a-form-model-item>
    </template>
    <template v-slot:button="{form}">
      <a-button type="primary" @click="exportData(form)" :loading="exporting">{{exporting ? '正在导出' : '数据导出'}}</a-button>
    </template>
    <template v-slot:edit="{form}">
      <a-form-model-item label="状态" prop="status">
        <a-select style="width: 100%" placeholder="请选择状态" :options="statusOptions" v-model="form.status" />
      </a-form-model-item>
      <a-form-model-item
        :label="typeTextMap.entry"
        prop="entry_date"
        v-if="form.status === 1">
        <a-date-picker
          v-model="form.entry_date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="选择日期"
          style="width: 100%;"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="typeTextMap.leave"
        prop="leave_date"
        v-else-if="form.status === 2">
        <a-date-picker
          v-model="form.leave_date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="选择日期"
          style="width: 100%;"
        />
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import chart from "@/components/chart.vue";
import {bookAgeList, bookStatus, educationList, sexList, getBookStatusText, getEducation} from "@/common/hr/book";
import {getAgeChartOptions, getEducationChartOptions, getStatusChartOptions} from "@/views/hr/file/chart";
import {downLoadBlobFile} from "@/common/js/tool";

export default {
  name: "HRBook",
  props: {
    dept: {
      type: Number,
      default: 1
    }
  },
  components: { chart },
  data() {
    return {
      columns: [
        {title: '法名/姓名', dataIndex: 'fm', customRender: (text, record) => text || <open-data type="userName" openid={record.wx_user_id} />},
        {title: '部门', dataIndex: 'dept', customRender: (text, record) => text || <open-data type="departmentName" openid={record.dept_id} />},
        {title: '学历', dataIndex: 'education', customRender: getEducation},
        {title: '联系电话', dataIndex: 'tel'},
        {title: '当前状态', dataIndex: 'status', customRender: text => getBookStatusText(text) + this.typeTextMap.name },
      ],
      educationOptions: [...educationList],
      ageOptions: [...bookAgeList],
      sexOptions: [...sexList],
      total: 0,
      ageChartOptions: undefined,
      educationChartOptions: undefined,
      statusChartOptions: undefined,
      exporting: false,
    }
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    type() {
      const type = this.$route.params.type;
      let res = 0;
      switch (type) {
        case "monk":
          res = 1;
          break;
        case "staff":
          res = 2;
          break;
      }
      return res;
    },
    typeTextMap() {
      const type = this.type;
      return {
        name: ['寺', '单', '职'][type] || '寺',
        entry: ['入寺日期', '进单日期', '入职日期'][type] || '入寺日期',
        leave: ['离寺日期', '离单日期', '离职日期'][type] || '离寺日期',
        title: ['其他人员', '僧众档案', '职工档案'][type] || '其他人员',
      };
    },
    statusOptions() {
      return bookStatus.map(item => new Object({ value: item.value, label: item.label + this.typeTextMap.name}));
    },
    rules() {
      const typeTextMap = this.typeTextMap;
      return {
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        entry_date: [{ required: true, message: `请选择${typeTextMap.entry}`, trigger: 'change' }],
        leave_date: [{ required: true, message: `请选择日期${typeTextMap.leave}`, trigger: 'change' }],
      }
    },
  },
  watch: {
    type() {
      this.getChartData();
    }
  },
  created() {
    this.getChartData();
  },
  methods: {
    exportData(form) {
      if(this.exporting) return;
      this.exporting = true;
      let fileName = this.typeTextMap.title;
      let url = '/admin/evaluate-user/files-export';
      this.$axios(url, {
        noTempleFilter: true,
        responseType: 'blob',
        params: this.getQueryParams(form),
      }).then(res => {
        downLoadBlobFile(res, fileName);
      }).finally(() => {
        this.exporting = false;
      });
    },
    getQueryParams(form) {
      const params = { type: this.type };
      if (form.education) {
        params.education = form.education;
      }
      if (form.age) {
        params[form.age] = 1;
      }
      if (form.sex && this.type === 2) {
        params.sex = form.sex;
      }
      if (form.status) {
        params.status = form.status;
      }
      return params;
    },
    beforeUpdate(form, fn) {
      const data = { id: form.id, status: form.status };
      let baseInfo = {};
      if (form.base_info) {
        try {
          baseInfo = JSON.parse(form.base_info);
        } catch (e){
          console.error(e)
        }
      }
      if (form.status === 1) {
        baseInfo.entry_date = form.entry_date;
      } else if(form.status === 2) {
        baseInfo.leave_date = form.leave_date;
      }
      data.base_info = JSON.stringify(baseInfo);
      fn(data);
    },
    showDetail(item) {
      this.$router.push({name: 'HRBookDetail', params: {id: item.wx_user_id}})
    },
    editRow(item) {
      this.$router.push({name: 'HRBookForm', params: {type: 'staff'}, query: {b: item.wx_user_id}})
    },
    changeStatus(item) {
      const form = {
        id: item.id,
        base_info: item.base_info,
        status: item.status,
      }
      if (item.status === 1) {
        form.entry_date = item.entry_date;
      } else if(item.status === 2) {
        form.leave_date = item.leave_date;
      }
      this.$refs.dataList.showEdit(form);
    },
    delRow(item) {
      const name = ['人员', '僧众', '职工'][item.type] || '人员';
      this.$confirm({
        title: '提示',
        content: `确定删除此${name}吗？`,
        onOk: () => {
          this.$axios({
            url: `/admin/evaluate-user/${item.id}`,
            method: 'DELETE'
          }).then(() => {
            this.$message.success('删除成功');
            this.getList();
          })
        }
      });
    },
    getChartData() {
      if(this.type === 0) return;
      this.$axios(`/admin/evaluate-user/archival-statistics?type=${this.type}`, { noTempleFilter: true }).then(res => {
        if(res.error === 0) {
          const data = res.data;
          const age = data.age;
          const ageData = bookAgeList.map(item => new Object({name: item.label, value: age[item.value.split("_")[1]]}))
          this.ageChartOptions = getAgeChartOptions(ageData, this.type);
          const education = res.data.education;
          const educationData = educationList.map(item => new Object({name: item.title}));
          educationData[0].value = education.Dr * 1;
          educationData[1].value = education.shuoshi * 1;
          educationData[2].value = education.benke * 1;
          educationData[3].value = education.junior_college * 1;
          educationData[4].value = education.high_school * 1;
          this.educationChartOptions = getEducationChartOptions(educationData);
          this.statusChartOptions = getStatusChartOptions({num: data.status * 1, per: data.status_percentage, type: this.type});
          this.total = data.total * 1;
        }
      })
    },
    customUrl(form) {
      let res = "";
      if (form.dept && form.dept.length > 0) {
        res += `&filter[dept_id]=${form.dept[0]}`;
      }
      if (form.keyword) {
        res += `&filter[or][0][name][like]=${form.keyword}&filter[or][1][fm][like]=${form.keyword}`;
      }
      if (form.sex && this.type === 2) {
        res += `&sex=${form.sex}`;
      }
      if (form.education) {
        res += `&education=${form.education}`;
      }
      if (form.age) {
        res += `&${form.age}=1`;
      }
      if (form.status) {
        res += `&filter[status]=${form.status}`;
      }
      return res;
    },
    dealList(list, fn) {
      list.forEach(item => {
        try {
          let detail = JSON.parse(item.base_info);
          for(let key in detail) {
            item[key] = detail[key];
          }
        } catch (e) {
          console.error(e);
        }
      })
      fn();
    },
    getList() {
      this.$refs.dataList.getList();
    }
  }
}
</script>

<style scoped lang="less">
.book-chart-card {
  background: transparent;
}
.book-chart-box {
  height: 200px;
}
</style>
