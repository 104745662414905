var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content md"},[_c('data-list',{ref:"dataList",staticStyle:{"height":"100%"},attrs:{"url":_vm.url,"query":"&expand=holiday","entity-name":"假期余额","add-btn":null,"hide-show-detail":"","hide-edit":"","hide-delete":"","modal-form":{layout: 'horizontal', labelAlign: 'left', labelCol: {span: 4}, wrapperCol: {span: 20}},"modalWidth":600,"action-width":200,"action":_vm.action,"columns":_vm.columns,"default-query":_vm.defaultQuery,"before-update":_vm.beforeUpdate,"search-key-type":{user_name: 1}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.is_limit == 0)?_c('span',{staticClass:"txt-btn grey",staticStyle:{"cursor":"default"}},[_vm._v("修改余额")]):_c('span',{staticClass:"txt-btn",on:{"click":function($event){$event.stopPropagation();return _vm.updateBalance(item, index)}}},[_vm._v("修改余额")]),_c('span',{staticClass:"txt-btn danger",on:{"click":function($event){$event.stopPropagation();return _vm.deleteBalance(item)}}},[_vm._v("删除")]),_c('span',{staticClass:"txt-btn",on:{"click":function($event){$event.stopPropagation();return _vm.showChangeList(item)}}},[_vm._v("变动记录")])]}},{key:"query",fn:function(ref){
var form = ref.form;
return [_c('a-form-model-item',{attrs:{"label":"假期类型"}},[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"选择假期类型筛选","options":_vm.holidayList,"allow-clear":""},on:{"change":_vm.updateList},model:{value:(form.holiday_id),callback:function ($$v) {_vm.$set(form, "holiday_id", $$v)},expression:"form.holiday_id"}})],1),_c('a-form-model-item',{attrs:{"label":"姓名/法名"}},[_c('a-input',{attrs:{"placeholder":"输入姓名/法名查询"},model:{value:(form.user_name),callback:function ($$v) {_vm.$set(form, "user_name", $$v)},expression:"form.user_name"}})],1)]}},{key:"edit",fn:function(ref){
var form = ref.form;
return [_c('a-form-model-item',{attrs:{"label":"姓名/法名"}},[_c('span',[_vm._v(_vm._s(form.user_name))])]),_c('a-form-model-item',{attrs:{"label":"身份"}},[_c('span',[_vm._v(_vm._s(_vm.getStaffTypeName(form.role)))])]),_c('a-form-model-item',{attrs:{"label":"假期类型"}},[_c('span',[_vm._v(_vm._s(form.holiday ? form.holiday.name : '--'))])]),_c('a-form-model-item',{attrs:{"label":"假期余额","prop":"count"}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入假期余额"},model:{value:(form.count),callback:function ($$v) {_vm.$set(form, "count", $$v)},expression:"form.count"}})],1)]}}])}),_c('a-modal',{attrs:{"width":640,"title":_vm.modalTitle,"footer":null},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('holiday-change',{attrs:{"holiday-count-id":_vm.visible && _vm.holidayCount ? _vm.holidayCount.id : 0}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }