<template>
  <a-table
    size="small"
    :columns="columns"
    :data-source="list"
    :pagination="pagination"
    :row-key="record => record.id"
    :loading="loading"
    @change="handleTableChange"
    bordered>
  </a-table>
</template>

<script>
    /**
     * 请假余额变动记录
     */
    export default {
        name: "holiday-change",
        props: {
            holidayCountId: Number, // 请假余额实体id
        },
        data() {
            return {
                columns: [
                    {title: '时间', dataIndex: 'create_time', width: 160},
                    {title: '操作', dataIndex: 'typeText', width: 120},
                    {title: '操作人', dataIndex: 'modify_user_id', width: 120, customRender: text => text ? <open-data type="userName" openid={text} /> : '--'},
                    {title: '变动时长', dataIndex: 'detail'},
                ],
                list: [],
                loading: false,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
            }
        },
        watch: {
            holidayCountId() {
                this.list = [];
                this.getChangeList();
            }
        },
        created() {
            this.getChangeList();
        },
        methods: {
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getChangeList();
            },
            getChangeList() {
                const id = this.holidayCountId;
                if(!id) return;
                this.loading = true;
                let url = `/admin/holiday-count-record`;
                let {current, pageSize} = this.pagination;
                url += `?sort=-id,page=${current}&pageSize=${pageSize}`;
                url += `&filter[holiday_count_id]=${id}`
                this.$axios(url).then(res => {
                    const list = res.data;
                    this.dealChangeList(list);
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                }).finally(() => this.loading = false)
            },
            dealChangeList(list) {
                list.forEach(item => {
                    let detail = "", typeText = "";
                    switch (item.type) {
                        case 1:
                            typeText = `请假${item.num}天`;
                            detail = `假期余额为${item.count}天`;
                            break;
                        case 2:
                            typeText = "人工修改";
                            detail = `假期余额为${item.count}天`;
                            break;
                        case 3:
                            typeText = "系统重置";
                            detail = `重置为${item.count}天`;
                            break;
                    }
                    item.detail = detail;
                    item.typeText = typeText;
                });
            }
        }
    }
</script>

<style scoped>

</style>
