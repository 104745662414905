import {bookAgeList} from "@/common/hr/book";

export function getAgeChartOptions(data, type) {
    if (type === 1) {
        return {
            color: ["#16D557", "#FF8C00", "#00A3FF", "#FFC700", "#BD00FF"],
            legend: {
                orient: "vertical",
                top: "middle",
                right: "5%",
            },
            series: [
                {
                    name: "年龄分布",
                    type: "pie",
                    radius: "80%",
                    center: ["30%", "50%"],
                    data,
                    label: {
                        show: true,
                        position: "inside",
                        formatter: "{c}人\n{d}%"
                    },
                    silent: true,
                    legendHoverLink: false,
                    emphasis: {
                        disabled: true, // 5.3.0以上支持
                    },
                },
            ],
        }
    } else {
        const yAxisData = bookAgeList.map(item => item.label);
        yAxisData.reverse();
        const maleData = [];
        const femaleData = [];
        data.forEach(item => {
            maleData.push({name: item.name, value: item.value.male * -1});
            femaleData.push({name: item.name, value: item.value.female * 1});
        })
        maleData.reverse();
        femaleData.reverse();
        return {
            color: ["#16D557", "#FF8C00", "#00A3FF", "#FFC700", "#BD00FF"],
            grid: {
                top: 16,
                left: 16,
                right: 16,
                bottom: 0,
                containLabel: true
            },
            xAxis: [
                {
                    type: 'value',
                    show: false,
                }
            ],
            yAxis: [
                {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    data: yAxisData
                }
            ],
            series: [
                {
                    name: '男',
                    type: 'bar',
                    stack: 'age',
                    label: {
                        show: true,
                        position: 'inside',
                        formatter: ({value}) => value * -1
                    },
                    data: maleData
                },
                {
                    name: '女',
                    type: 'bar',
                    stack: 'age',
                    label: {
                        show: true,
                        position: 'inside',
                    },
                    data: femaleData
                },
            ],
        }
    }
}

export function getEducationChartOptions(data) {
    return {
        color: ["#16D557", "#FF8C00", "#00A3FF", "#FFC700", "#BD00FF"],
        legend: {
            orient: "vertical",
            top: "middle",
            right: "5%",
        },
        series: [
            {
                name: '学历分布',
                type: 'pie',
                radius: ['50%', '80%'],
                center: ['35%', '50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    position: 'inside',
                    formatter: "{d}%",
                },
                labelLine: {
                    show: false
                },
                data
            }
        ]
    }
}

export function getStatusChartOptions(data) {
    return {
        series: [
            {
                //第二个球的填充
                type: "liquidFill",
                radius: "90%",
                center: ["50%", "50%"],
                color: [
                    {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: "#2aa1e3",
                            },
                            {
                                offset: 1,
                                color: "#08bbc9",
                            },
                        ],
                        globalCoord: false,
                    },
                ],
                data: [0.5, 0.47, 0.45], // data个数代表波浪数
                backgroundStyle: {
                    borderWidth: 1,
                    color: "RGBA(51, 66, 127, 0.7)",
                },
                label: {
                    rich: {
                        value: {
                            fontSize: 20,
                            lineHeight: 84,
                            color: "#ffffff",
                        },
                        label: {
                            fontSize: 18,
                            color: "#ffffff",
                        },
                    },
                    formatter: function() {
                        return `{value|${['在寺', '在单', '在职'][data.type]}人数${data.num}}\n{label|${data.per}}`;
                    },
                },
                outline: {
                    // show: false
                    borderDistance: 0,
                    itemStyle: {
                        borderWidth: 0,
                        borderColor: "#112165",
                    },
                },
            },
        ],
    }
}