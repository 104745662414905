var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-list',{ref:"dataList",staticClass:"content lg",attrs:{"url":"/admin/evaluate-user","entity-name":"成员","hide-show-detail":"","hide-edit":"","hide-delete":"","add-btn":false,"query":("&filter[type]=" + _vm.type),"custom-url":_vm.customUrl,"deal-list":_vm.dealList,"action":_vm.action,"columns":_vm.columns,"rules":_vm.rules,"before-update":_vm.beforeUpdate,"action-width":240},scopedSlots:_vm._u([(_vm.type > 0)?{key:"prefix",fn:function(){return [_c('a-row',{staticClass:"book-chart",attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-card',{staticClass:"book-chart-card",attrs:{"body-style":{padding: 0},"title":"年龄分布","bordered":false}},[_c('chart',{staticClass:"book-chart-box",attrs:{"option":_vm.ageChartOptions}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-card',{staticClass:"book-chart-card",attrs:{"body-style":{padding: 0},"title":"学历分布","bordered":false}},[_c('chart',{staticClass:"book-chart-box",attrs:{"option":_vm.educationChartOptions}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-card',{staticClass:"book-chart-card",attrs:{"body-style":{padding: 0},"bordered":false}},[_c('div',{staticClass:"flex-box justify-between",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"book-chart-title"},[_vm._v("状态分布")]),_c('div',{staticClass:"book-total"},[_vm._v("总人数"+_vm._s(_vm.total)+"人")])]),_c('chart',{staticClass:"book-chart-box",attrs:{"option":_vm.statusChartOptions}})],1)],1)],1)]},proxy:true}:null,{key:"action",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"txt-btn",on:{"click":function($event){$event.stopPropagation();return _vm.showDetail(item)}}},[_vm._v("查看")]),_c('a',{staticClass:"txt-btn",on:{"click":function($event){$event.stopPropagation();return _vm.editRow(item)}}},[_vm._v("编辑")]),_c('a',{staticClass:"txt-btn",on:{"click":function($event){$event.stopPropagation();return _vm.changeStatus(item)}}},[_vm._v("修改状态")]),_c('a',{staticClass:"txt-btn danger",on:{"click":function($event){$event.stopPropagation();return _vm.delRow(item)}}},[_vm._v("删除")])]}},{key:"query",fn:function(ref){
var form = ref.form;
return [_c('a-form-model-item',{attrs:{"label":"部门"}},[_c('select-book',{attrs:{"type":"dept"},on:{"change":_vm.getList},model:{value:(form.dept),callback:function ($$v) {_vm.$set(form, "dept", $$v)},expression:"form.dept"}})],1),_c('a-form-model-item',{attrs:{"label":""}},[_c('a-input-search',{staticStyle:{"width":"180px"},attrs:{"allow-clear":"","placeholder":"输入姓名/法名搜索"},on:{"search":_vm.getList},model:{value:(form.keyword),callback:function ($$v) {_vm.$set(form, "keyword", $$v)},expression:"form.keyword"}})],1),(_vm.type === 2)?_c('a-form-model-item',{attrs:{"label":""}},[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"allowClear":"","placeholder":"选择性别筛选","options":_vm.sexOptions},on:{"change":_vm.getList},model:{value:(form.sex),callback:function ($$v) {_vm.$set(form, "sex", $$v)},expression:"form.sex"}})],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":""}},[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"allowClear":"","placeholder":"选择学历筛选","options":_vm.educationOptions},on:{"change":_vm.getList},model:{value:(form.education),callback:function ($$v) {_vm.$set(form, "education", $$v)},expression:"form.education"}})],1),_c('a-form-model-item',{attrs:{"label":""}},[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"allowClear":"","placeholder":"选择年龄段筛选","options":_vm.ageOptions},on:{"change":_vm.getList},model:{value:(form.age),callback:function ($$v) {_vm.$set(form, "age", $$v)},expression:"form.age"}})],1),_c('a-form-model-item',{attrs:{"label":""}},[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"allowClear":"","placeholder":"选择状态筛选","options":_vm.statusOptions},on:{"change":_vm.getList},model:{value:(form.status),callback:function ($$v) {_vm.$set(form, "status", $$v)},expression:"form.status"}})],1)]}},{key:"button",fn:function(ref){
var form = ref.form;
return [_c('a-button',{attrs:{"type":"primary","loading":_vm.exporting},on:{"click":function($event){return _vm.exportData(form)}}},[_vm._v(_vm._s(_vm.exporting ? '正在导出' : '数据导出'))])]}},{key:"edit",fn:function(ref){
var form = ref.form;
return [_c('a-form-model-item',{attrs:{"label":"状态","prop":"status"}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择状态","options":_vm.statusOptions},model:{value:(form.status),callback:function ($$v) {_vm.$set(form, "status", $$v)},expression:"form.status"}})],1),(form.status === 1)?_c('a-form-model-item',{attrs:{"label":_vm.typeTextMap.entry,"prop":"entry_date"}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD","value-format":"YYYY-MM-DD","placeholder":"选择日期"},model:{value:(form.entry_date),callback:function ($$v) {_vm.$set(form, "entry_date", $$v)},expression:"form.entry_date"}})],1):(form.status === 2)?_c('a-form-model-item',{attrs:{"label":_vm.typeTextMap.leave,"prop":"leave_date"}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD","value-format":"YYYY-MM-DD","placeholder":"选择日期"},model:{value:(form.leave_date),callback:function ($$v) {_vm.$set(form, "leave_date", $$v)},expression:"form.leave_date"}})],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }